  <template>
  <swiper :pagination="true" :direction="'horizontal'" v-if="attachments">
    <swiper-slide>
      <section class="frame scroll">
        <div class="content">
          <div class="question-container">
            <h4 class="color-accent">
              {{ h }}
            </h4>
            <p>
              {{ question }}
            </p>
          </div>
        </div>
        <div class="content">
          <div class="answer-container">
            <textarea
              v-model="answer"
              name="textarea"
              rows="10"
              placeholder="Vastaa tähän omin sanoin."
            ></textarea>

            <button @click="submitAnswer" class="btn btn-primary btn-submit">
              Vastaa
            </button>
          </div>
        </div>
        <div class="content">
          <p><i>* Huom! Pyyhkäise vasemmalle katsoaksesi liitteitä.</i></p>
        </div>
        <div class="content">
          <FrameTwoButtons
            @nextFrame="$emit('nextFrame')"
            @previousFrame="$emit('previousFrame')"
            textLeft="Edellinen"
            textRight="Seuraava"
          />
        </div>
      </section>
      <div class="feedback-container" :class="{ active: showSuccess }">
        <div class="frame">
          <div class="content text-center">
            <img src="@/assets/correct.svg" />
            <h2 class="color-success">Vastaus rekisteröity</h2>
            <h3>Hieno homma!<br />Oliko tämä kysymys..</h3>
          </div>
          <div class="content">
            <div class="answer-container">
              <button
                @click="setFeedbackSelection(option)"
                :key="option"
                v-for="option in successOptions"
                class="btn btn-primary"
                :class="{ active: feedbackSelection === option }"
              >
                {{ option }}
              </button>
            </div>
          </div>
          <div class="content">
            <FrameTwoButtons
              @nextFrame="$emit('nextFrame')"
              @previousFrame="$emit('previousFrame')"
              textLeft="Edellinen"
              textRight="Seuraava"
            />
          </div>
        </div>
      </div>
      <div class="feedback-container" :class="{ active: showFailed }">
        <div class="frame">
          <div class="content text-center">
            <img src="@/assets/incorrect.svg" />
            <h2 class="color-error">Et vastannut kysymykseen</h2>
          </div>
          <div class="content">
            <div class="answer-container">
              <button @click="closeFeedback()" class="btn btn-primary">
                Yritä uudestaan
              </button>
            </div>
          </div>
          <div class="content">
            <FrameTwoButtons
              @nextFrame="$emit('nextFrame')"
              @previousFrame="$emit('previousFrame')"
              textLeft="Edellinen"
              textRight="Seuraava"
            />
          </div>
        </div>
      </div>
    </swiper-slide>
    <swiper-slide
      v-for="(item, index) in attachments"
      :key="'attachment' + index"
    >
      <section class="frame scroll">
        <div class="content" v-html="item"></div>
      </section>
    </swiper-slide>
  </swiper>
  <section class="frame scroll" v-else>
    <div class="content">
      <div class="question-container">
        <h4 class="color-accent">
          {{ h }}
        </h4>
        <p>
          {{ question }}
        </p>
      </div>
    </div>
    <div class="content">
      <div class="answer-container">
        <textarea
          name="textarea"
          rows="10"
          placeholder="Vastaa tähän omin sanoin."
          v-model="answer"
        ></textarea>
        <button @click="submitAnswer" class="btn btn-primary btn-submit">
          Vastaa
        </button>
      </div>
    </div>

    <div class="content">
      <FrameTwoButtons
        @nextFrame="$emit('nextFrame')"
        @previousFrame="$emit('previousFrame')"
        textLeft="Edellinen"
        textRight="Seuraava"
      />
    </div>
  </section>
  <div class="feedback-container" :class="{ active: showSuccess }">
    <div class="frame">
      <div class="content text-center">
        <img src="@/assets/correct.svg" />
        <h2 class="color-success">Vastaus rekisteröity</h2>
        <h3>Hieno homma!<br />Oliko tämä kysymys..</h3>
      </div>
      <div class="content">
        <div class="answer-container">
          <button
            @click="setFeedbackSelection(option)"
            :key="option"
            v-for="option in successOptions"
            class="btn btn-primary"
            :class="{ active: feedbackSelection === option }"
          >
            {{ option }}
          </button>
        </div>
      </div>
      <div class="content">
        <FrameTwoButtons
          @nextFrame="$emit('nextFrame')"
          @previousFrame="$emit('previousFrame')"
          textLeft="Edellinen"
          textRight="Seuraava"
        />
      </div>
    </div>
  </div>
  <div class="feedback-container" :class="{ active: showFailed }">
    <div class="frame">
      <div class="content text-center">
        <img src="@/assets/incorrect.svg" />
        <h2 class="color-error">Et vastannut kysymykseen</h2>
      </div>
      <div class="content">
        <div class="answer-container">
          <button @click="closeFeedback()" class="btn btn-primary">
            Yritä uudestaan
          </button>
        </div>
      </div>
      <div class="content">
        <FrameTwoButtons
          @nextFrame="$emit('nextFrame')"
          @previousFrame="$emit('previousFrame')"
          textLeft="Edellinen"
          textRight="Seuraava"
        />
      </div>
    </div>
  </div>
</template>
<script>
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
import FrameTwoButtons from "./FrameTwoButtons.vue";

export default {
  name: "FrameQuestionText",
  emits: ["nextFrame", "previousFrame"],
  props: {
    title: String,
    h: String,
    question: String,
    attachments: Array,
  },
  data() {
    return {
      successOptions: ["Liian helppo", "Hieman vaikeahko", "Just hyvä!"],
      errorOptions: ["Lisää videomateriaalia", "Lisää käytännönharjoituksia"],
      answer: null,
      feedbackSelection: null,
      showSuccess: false,
      showFailed: false,
      userAnswer: { q: null, a: null, feedback: null },
    };
  },
  components: {
    Swiper,
    SwiperSlide,
    FrameTwoButtons,
  },
  methods: {
    closeFeedback() {
      this.showFailed = false;
    },
    setFeedbackSelection(selection) {
      if (this.feedbackSelection === null) {
        this.feedbackSelection = selection;
        this.userAnswer.feedback = selection;
        setTimeout(() => {
          this.$emit("nextFrame");
        }, 500);
      }
    },
    submitAnswer() {
      this.userAnswer.q = this.question;
      this.userAnswer.a = this.answer;
      setTimeout(() => {
        if (this.answer === null) {
          this.showFailed = true;
        } else {
          this.showSuccess = true;
          this.$store.dispatch("setAnswer", this.userAnswer);
        }
      }, 500);
    },
  },
};
</script>
