<template>
  <section class="frame scroll">
    <div class="content">
      <div class="question-container">
        <h4 class="color-accent">Kysymys {{ index + 1 }}:</h4>
        <p>{{ question }}</p>
      </div>
      <div class="answer-container">
        <button
          @click="setSelection(index + 1)"
          :key="option"
          v-for="(option, index) in options"
          class="btn btn-answer"
          :class="{ active: selection(index + 1) }"
        >
          {{ option }}
        </button>
        <div>
          <button class="btn btn-primary btn-submit" @click="checkSelections">
            Valitse
          </button>
        </div>
      </div>
    </div>
    <div class="content">
      <FrameTwoButtons
        @nextFrame="$emit('nextFrame')"
        @previousFrame="$emit('previousFrame')"
        textLeft="Edellinen"
        textRight="Seuraava"
      />
    </div>
    <div class="feedback-container" :class="{ active: showCorrect }">
      <div class="frame">
        <div class="content text-center">
          <img src="@/assets/correct.svg" />
          <h2 class="color-success">Oikein</h2>
          <h3>
            <span class="title">Hieno homma!</span> <br /><span class="subtitle"
              >Oliko tämä kysymys..</span
            >
          </h3>
        </div>
        <div class="content">
          <div class="answer-container">
            <button
              @click="setFeedbackSelection(option)"
              :key="option"
              v-for="option in successOptions"
              class="btn btn-primary"
              :class="{ active: feedbackSelection === option }"
            >
              {{ option }}
            </button>
          </div>
        </div>
        <div class="content">
          <FrameTwoButtons
            @nextFrame="$emit('nextFrame')"
            @previousFrame="$emit('previousFrame')"
            textLeft="Edellinen"
            textRight="Seuraava"
          />
        </div>
      </div>
    </div>
    <div class="feedback-container" :class="{ active: showIncorrect }">
      <div class="frame">
        <div class="content text-center">
          <img src="@/assets/incorrect.svg" />
          <h2 class="color-error">Väärin</h2>
          <h3>
            <span class="title"> Melkein! </span><br />
            <span class="subtitle"
              >Mitä tarvitsisit saadaksesi tämän oikein?</span
            >
          </h3>
        </div>
        <div class="content">
          <div class="answer-container">
            <button
              @click="setFeedbackSelection(option)"
              :key="option"
              v-for="option in errorOptions"
              class="btn btn-primary"
              :class="{ active: feedbackSelection === option }"
            >
              {{ option }}
            </button>
          </div>
        </div>
        <div class="content">
          <FrameTwoButtons
            @nextFrame="$emit('nextFrame')"
            @previousFrame="$emit('previousFrame')"
            textLeft="Edellinen"
            textRight="Seuraava"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import FrameTwoButtons from "./FrameTwoButtons.vue";

export default {
  name: "FrameQuestionMulti",
  props: {
    index: Number,
    question: String,
    options: Array,
    answers: Array,
  },
  data() {
    return {
      checkedAnswers: [],
      successOptions: ["Liian helppo", "Hieman vaikeahko", "Just hyvä!"],
      errorOptions: ["Lisää videomateriaalia", "Lisää käytännönharjoituksia"],
      feedbackSelection: null,
      showCorrect: false,
      showIncorrect: false,
      userAnswer: { q: null, a: [], feedback: null, result: null },
    };
  },
  emits: ["nextFrame", "previousFrame"],
  components: { FrameTwoButtons },
  methods: {
    selection(option) {
      return this.userAnswer.a.includes(option);
    },
    setSelection(option) {
      if (!this.userAnswer.a.includes(option)) {
        this.userAnswer.a.push(option);
      } else {
        this.userAnswer.a = this.userAnswer.a.filter((arr) => {
          return arr != option;
        });
      }
    },
    checkSelections() {
      this.userAnswer.q = this.question;
      setTimeout(() => {
        if (this.userAnswer.a.length === this.answers.length) {
          this.userAnswer.a.every((value) => {
            if (this.answers.includes(value)) {
              this.userAnswer.result = true;
              this.showCorrect = true;
            } else {
              this.userAnswer.result = false;
              this.showIncorrect = true;
            }
          });
        } else {
          this.userAnswer.result = false;
          this.showIncorrect = true;
        }
      }, 500);
    },
    setFeedbackSelection(selection) {
      if (this.feedbackSelection === null) {
        this.feedbackSelection = selection;
        this.userAnswer.feedback = selection;
        setTimeout(() => {
          this.$emit("nextFrame");
        }, 500);
      }
      this.$store.dispatch("setAnswer", this.userAnswer);
    },
  },
};
</script>
