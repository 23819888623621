<template>
  <section class="frame module-summary">
    <div class="content">
      <h2 class="page-title">Hyvin tehty!</h2>
    </div>
    <div class="progress">
      <div
        class="progress-bar"
        role="progressbar"
        :aria-valuenow="(questionsCorrect / questionsTotal) * 100"
        aria-valuemin="0"
        aria-valuemax="100"
        :style="{ width: (questionsCorrect / questionsTotal) * 100 + '%' }"
      ></div>
    </div>
    <div class="content row grades">
      <div class="col grade">
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="7" cy="7" r="7" fill="#ff356f" />
        </svg>
        <strong>{{ questionsAnswered }} / {{ questionsTotal }}</strong>
        VASTATTU
      </div>
      <div class="col grade">
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="7" cy="7" r="7" fill="#ff356f" />
        </svg>
        <strong>{{ questionsCorrect }} / {{ questionsTotal }}</strong>
        OIKEIN
      </div>
      <div class="col grade">
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="7" cy="7" r="7" fill="#ff356f" />
        </svg>
        <strong
          >{{
            Math.round((questionsCorrect / questionsTotal) * 100)
          }}
          % </strong
        >OIKEIN
      </div>
    </div>
    <div class="content">
      <p><i>* Huom! Lopputulos ei ota huomioon avoimia kysymyksiä.</i></p>
    </div>
    <div class="content">
      <FrameTwoButtons
        @nextFrame="exitModule"
        @previousFrame="this.$emit('restartCourse')"
        textLeft="Aloita alusta"
        textRight="Kursseihin"
      />
    </div>
  </section>
</template>

<script>
import FrameTwoButtons from "../components/FrameTwoButtons.vue";
export default {
  data() {
    return {};
  },
  components: {
    FrameTwoButtons,
  },
  computed: {
    questionsTotal() {
      let count = 0;
      let assignments = this.$store.getters.moduleData(
        this.$route.params.moduleid
      ).assignments;
      for (let obj of assignments) {
        count = count + obj.questions.length;
      }
      return count;
    },
    questionsAnswered() {
      if (
        this.$store.getters.userState.active[this.$route.params.moduleid] &&
        this.$store.getters.userState.active[this.$route.params.moduleid]
          .answers
      ) {
        return this.$store.getters.userState.active[this.$route.params.moduleid]
          .answers.length;
      } else {
        return 0;
      }
    },
    questionsCorrect() {
      let correct = 0;
      if (
        this.$store.getters.userState.active[this.$route.params.moduleid] &&
        this.$store.getters.userState.active[this.$route.params.moduleid]
          .answers
      ) {
        let answers =
          this.$store.getters.userState.active[this.$route.params.moduleid]
            .answers;
        for (let obj of answers) {
          if (obj.result === true || obj.a === true) {
            correct++;
          }
        }
      }
      return correct;
    },
  },
  methods: {
    exitModule() {
      this.$router.push({ name: "Dashboard" });
    },
  },
};
</script>

<style>
</style>