<template>
  <swiper
    :pagination="true"
    :direction="'horizontal'"
    v-if="(slide.img || slide.attachments) && slide.p"
  >
    <swiper-slide>
      <section class="frame scroll">
        <div class="content">
          <h4 class="color-accent">{{ slide.h }}</h4>
        </div>
        <!-- if p === object -->
        <div class="content text-left">
          <template v-for="(p, index) in slide.p" :key="'p' + index">
            <template v-if="typeof p === 'object' && p !== null">
              <p v-for="(value, item) in p" :key="item">
                <span class="color-accent" v-html="item + ': '"></span>
                <span v-html="value"></span>
              </p>
            </template>
            <template class="content text-left" v-else>
              <p v-html="p"></p>
            </template>
          </template>
        </div>
        <div class="content">
          <p><i>* Huom! Pyyhkäise vasemmalle katsoaksesi liitteitä.</i></p>
        </div>
        <div class="content">
          <FrameTwoButtons
            @nextFrame="$emit('nextFrame')"
            @previousFrame="$emit('previousFrame')"
            textLeft="Edellinen"
            textRight="Seuraava"
          />
        </div>
      </section>
    </swiper-slide>
    <template v-if="slide.img">
      <swiper-slide>
        <section class="frame scroll">
          <div class="content">
            <img
              alt="image attachment"
              :src="require(`../assets/img/${slide.img}`)"
              style="width: 100%"
              v-if="slide.img"
            />
          </div></section
      ></swiper-slide>
    </template>
    <template v-if="slide.attachments">
      <swiper-slide
        v-for="(item, index) in slide.attachments"
        :key="'attachment' + index"
      >
        <section class="frame scroll">
          <div class="content" v-html="item"></div>
        </section>
      </swiper-slide>
    </template>
  </swiper>
  <!-- no attachments -->
  <section class="frame scroll" v-else>
    <div class="content">
      <h4 class="color-accent">{{ slide.h }}</h4>
    </div>
    <div class="content text-left">
      <template v-for="(p, index) in slide.p" :key="'p' + index">
        <template v-if="typeof p === 'object' && p !== null">
          <p v-for="(value, item) in p" :key="item">
            <span class="color-accent" v-html="item + ': '"></span
            ><span v-html="value"></span>
          </p>
        </template>
        <template v-else>
          <p v-html="p"></p>
        </template>
      </template>
    </div>
    <div class="content" v-if="slide.img">
      <img
        alt="image attachment"
        :src="require(`../assets/img/${slide.img}`)"
        style="width: 100%"
      />
    </div>
    <div class="content">
      <FrameTwoButtons
        @nextFrame="$emit('nextFrame')"
        @previousFrame="$emit('previousFrame')"
        textLeft="Edellinen"
        textRight="Seuraava"
      />
    </div>
  </section>
</template>

<script>
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
import FrameTwoButtons from "./FrameTwoButtons.vue";
export default {
  name: "FrameslideText",
  emits: ["nextFrame", "previousFrame"],
  props: {
    slide: Object,
  },
  components: {
    Swiper,
    SwiperSlide,
    FrameTwoButtons,
  },
};
</script>

<style></style>
