import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import AvailableCourses from "../views/AvailableCourses.vue";
import ActiveCourses from "../views/ActiveCourses.vue";
import Course from "../views/Course.vue";
import ModulesFeed from "../views/ModulesFeed.vue";
import Module from "../views/Module.vue";
import Glossary from "../views/Glossary.vue";
import PageNotFound from "../views/PageNotFound.vue";
import Login from "../views/Login.vue";
import firebase from "../firebaseInit";

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,

    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/courses/available",
    name: "AvailableCourses",
    component: AvailableCourses,
  },
  {
    path: "/courses/active",
    name: "ActiveCourses",
    component: ActiveCourses,
  },
  {
    path: "/course/:courseid",
    name: "Course",
    component: Course,
  },
  {
    path: "/modules/:courseid/",
    name: "Course modules",
    component: ModulesFeed,
  },
  {
    path: "/module/:moduleid",
    name: "Module",
    component: Module,
  },
  {
    path: "/glossary/:moduleid/",
    name: "Glossary",
    component: Glossary,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: PageNotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (requiresAuth && !(await firebase.getCurrentUser())) {
    next("Login");
  } else {
    next();
  }
});

export default router;
