import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";

import Vue3VideoPlayer from "@cloudgeek/vue3-video-player";
import "@cloudgeek/vue3-video-player/dist/vue3-video-player.css";
import './registerServiceWorker'

const app = createApp(App);
window.addEventListener("load",function() {
  setTimeout(function(){
      // Hide the address bar:
      window.scrollTo(0, 1);
  }, 0);
});
app
  .use(Vue3VideoPlayer, {
    lang: "zh-CN",
  })
  .use(store)
  .use(router)
  .mount("#app");
