<template>
  <section class="frame scroll">
    <div class="content">
      <h4 class="page-title color-accent">{{ courseData.title }}</h4>
    </div>
    <div class="content">
      <h4>Haluatko katsoa videoita vai lukea tekstiä?</h4>
      <button @click="togglePref('text')" class="btn btn-primary">
        Teksti
      </button>
      <button @click="togglePref('video')" class="btn btn-primary">
        Video
      </button>
    </div>
    <div class="content" v-if="this.pref === 'video'">
      <div class="content" v-if="courseData.video">
        <div class="video-square">
          <div v-if="!isPlaying" class="video-overlay">
            <button @click="toggleVideo()" class="btn">
              <img src="../assets/play_overlay.png" />
            </button>
          </div>
          <video
            controls
            controlsList="nodownload"
            webkit-playsinline
            playsinline
            poster="../assets/videothumb.png"
            ref="introVideo"
          >
            <source :src="courseData.video" />
          </video>
        </div>
      </div>
    </div>

    <div class="content text-left" v-else-if="this.pref === 'text'">
      <div class="content text-left">
        <p v-for="(p, n) in courseData.text" :key="'p' + n" v-html="p"></p>
      </div>
    </div>
    <div class="content">
      <button @click="startCourse" class="btn btn-secondary">
        Aloita kurssi
      </button>
    </div>
  </section>
</template>
<script>
export default {
  name: "Dashboard",
  data() {
    return {
      isPlaying: false,
      pref: null,
    };
  },
  components: {},
  mounted() {},
  computed: {
    courseId() {
      return this.$route.params.courseid;
    },
    courseData() {
      return this.$store.getters.courseData(this.courseId);
    },
  },
  methods: {
    togglePref(mode) {
      this.pref = mode;
    },
    startCourse() {
      if (this.courseData.modules) {
        this.$router.push("/modules/" + this.courseId);
      } else {
        return;
      }
    },
    toggleVideo() {
      if (this.isPlaying) {
        this.stopVideo();
        this.controls = "controls";
      } else {
        this.playVideo();
        this.controls = " ";
      }
    },
    playVideo() {
      this.$refs.introVideo.play();
      this.isPlaying = true;
    },
    stopVideo() {
      this.$refs.introVideo.pause();
      this.isPlaying = false;
    },
  },
};
</script>