<template>
  <section class="login">
    <!-- <div class="logo">logo</div> -->
    <img
      alt="Aalto University"
      class="logo"
      :class="{ disabled: this.$route.name !== 'Dashboard' }"
      src="../assets/aalto.png"
    />
    <div v-if="error" class="alert">{{ error }}</div>
    <form action="#" @submit.prevent="submit">
      <div class="form-group">
        <label for="email">Email</label>
        <input
          id="email"
          type="email"
          class="form-control"
          name="email"
          placeholder="Enter email"
          value
          required
          autofocus
          v-model="form.email"
        />
      </div>

      <div class="form-group">
        <label for="password">Password</label>

        <input
          id="password"
          type="password"
          class="form-control"
          name="password"
          placeholder="Enter password"
          required
          v-model="form.password"
        />
      </div>

      <div class="form-group text-center">
        <button type="submit" class="btn btn-primary">Login</button>
      </div>
    </form>
  </section>
</template>
<script>
import firebase from "../firebaseInit";
export default {
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      error: null,
    };
  },
  methods: {
    submit() {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.form.email, this.form.password)
        .then(() => {
          this.$router.replace({ name: "Dashboard" });
        })
        .catch((err) => {
          this.error = err.message;
        });
    },
  },
};
</script>
