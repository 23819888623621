<template>
  <div class="modal-backdrop">
    <div
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content text-center">
          <section class="modal-body">
            <h4>
              <span class="title">
                <slot name="header">Haluatko varmasti lopettaa?</slot>
              </span>
            </h4>
            <span class="subtitle">
              <slot name="body"> Voit jatkaa milloin vaan. </slot>
            </span>
          </section>

          <footer class="modal-footer">
            <div class="content">
              <button
                type="button"
                class="btn btn-primary"
                data-dismiss="modal"
                @click="closeModal"
              >
                <span class="color-error">Ei</span>
              </button>
              <button
                type="button"
                class="btn btn-primary"
                data-dismiss="modal"
                @click="quitModal"
              >
                <span class="color-success">Kyllä</span>
              </button>
            </div>
          </footer>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "AppModal",
  methods: {
    closeModal() {
      this.$emit("close");
    },
    quitModal() {
      this.$emit("quit");
    },
  },
};
</script>

<style>
</style>