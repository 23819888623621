<template>
  <section class="frame scroll">
    <div class="content">
      <div class="question-container">
        <h4 class="color-accent">Kysymys {{ index + 1 }}:</h4>
        <p>{{ question }}</p>
      </div>
      <div class="answer-container">
        <button
          @click="setSelection(option, index)"
          :key="option"
          v-for="(option, index) in options"
          class="btn btn-answer"
          :class="{ active: selection === option }"
        >
          {{ option }}
        </button>
      </div>
    </div>
    <div class="content">
      <FrameTwoButtons
        @nextFrame="$emit('nextFrame')"
        @previousFrame="$emit('previousFrame')"
        textLeft="Edellinen"
        textRight="Seuraava"
      />
    </div>

    <div class="feedback-container" :class="{ active: showCorrect }">
      <div class="frame">
        <div class="content text-center">
          <img src="@/assets/correct.svg" />
          <h2 class="color-success">Oikein</h2>
          <h3>
            <span class="title">Hieno homma!</span> <br /><span class="subtitle"
              >Oliko tämä kysymys..</span
            >
          </h3>
        </div>
        <div class="content">
          <div class="answer-container">
            <button
              @click="setFeedbackSelection(option)"
              :key="option"
              v-for="option in successOptions"
              class="btn btn-primary"
              :class="{ active: feedbackSelection === option }"
            >
              {{ option }}
            </button>
          </div>
        </div>
        <div class="content">
          <!--           <FrameTwoButtons
            @nextFrame="$emit('nextFrame')"
            @previousFrame="$emit('previousFrame')"
            textLeft="Edellinen"
            textRight="Seuraava"
          /> -->
          <button @click="previousFrameSave" class="btn btn-primary">
            <span class="color-error"> Edellinen </span>
          </button>
          <button @click="nextFrameSave" class="btn btn-primary">
            <span class="color-success">Seuraava </span>
          </button>
        </div>
      </div>
    </div>
    <div class="feedback-container" :class="{ active: showIncorrect }">
      <div class="frame">
        <div class="content text-center">
          <img src="@/assets/incorrect.svg" />
          <h2 class="color-error">Väärin</h2>
          <h3>
            <span class="title"> Melkein! </span><br />
            <span class="subtitle"
              >Mitä tarvitsisit saadaksesi tämän oikein?</span
            >
          </h3>
        </div>
        <div class="content">
          <div class="answer-container">
            <button
              @click="setFeedbackSelection(option)"
              :key="option"
              v-for="option in errorOptions"
              class="btn btn-primary"
              :class="{ active: feedbackSelection === option }"
            >
              {{ option }}
            </button>
          </div>
        </div>
        <div class="content">
          <!--           <FrameTwoButtons
            @nextFrame="$emit('nextFrame')"
            @previousFrame="$emit('previousFrame')"
            textLeft="Edellinen"
            textRight="Seuraava"
          /> -->
          <button @click="previousFrameSave" class="btn btn-primary">
            <span class="color-error"> Edellinen </span>
          </button>
          <button @click="nextFrameSave" class="btn btn-primary">
            <span class="color-success">Seuraava </span>
          </button>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import FrameTwoButtons from "./FrameTwoButtons.vue";

export default {
  name: "FrameQuestionComplex",
  props: {
    index: Number,
    question: String,
    options: Array,
    answer: Number,
  },
  data() {
    return {
      successOptions: ["Liian helppo", "Hieman vaikeahko", "Just hyvä!"],
      errorOptions: [
        "Lisää videomateriaalia",
        "Lisää käytännönharjoituksia"
      ],
      feedbackSelection: null,
      selection: null,
      showCorrect: false,
      showIncorrect: false,
      userAnswer: { q: null, a: null, feedback: null, result: null },
    };
  },
  components: { FrameTwoButtons },
  methods: {
    setSelection(selection, index) {
      if (this.selection === null) {
        this.selection = selection;
        this.userAnswer.q = this.question;
        setTimeout(() => {
          if (index === this.answer) {
            this.userAnswer.a = selection;
            this.userAnswer.result = true;
            this.showCorrect = true;
          } else {
            this.userAnswer.a = selection;
            this.userAnswer.result = false;
            this.showIncorrect = true;
          }
        }, 500);
      }
    },
    setFeedbackSelection(selection) {
      if (this.feedbackSelection === null) {
        this.feedbackSelection = selection;
        this.userAnswer.feedback = selection;
        setTimeout(() => {
          this.$emit("nextFrame");
        }, 500);
      }
      this.$store.dispatch("setAnswer", this.userAnswer);
    },
    nextFrameSave() {
      this.$store.dispatch("setAnswer", this.userAnswer);
      this.$emit("nextFrame");
    },
    previousFrameSave() {
      this.$store.dispatch("setAnswer", this.userAnswer);
      this.$emit("previousFrame");
    },
  },
};
</script>
