<template>
  <section class="frame scroll">
    <div class="content">
      <h4 class="color-accent">{{ slide.h }}</h4>
    </div>
    <div class="content" v-if="slide.img">
      <figure>
        <img
          alt="image attachment"
          :src="require(`../assets/img/${slide.img}`)"
          style="width: 100%"
        />
        <figcaption v-if="slide.caption" v-html="slide.caption"></figcaption>
      </figure>
    </div>
    <div class="content">
      <FrameTwoButtons
        @nextFrame="$emit('nextFrame')"
        @previousFrame="$emit('previousFrame')"
        textLeft="Edellinen"
        textRight="Seuraava"
      />
    </div>
  </section>
</template>

<script>
import FrameTwoButtons from "./FrameTwoButtons.vue";
export default {
  name: "FrameslideText",
  props: {
    slide: Object,
  },
  components: {
    FrameTwoButtons,
  },
};
</script>

<style></style>
