<template>
  <div class="screen screen-courses-feed screen-full">
    <div class="content-wrap">
      <div class="page-title-container">
        <h2 class="page-title"> Moduulit</h2>
      </div>
      <template v-for="(course) in availableCourses" :key="course.title">
        <router-link :to="'/course/' + course.id" class="neo-container-mid">
          <div class="information-container">
            <span class="counter counter-primary"> i </span>
            <div class="wrap">
              <span class="title"> {{ course.title }}</span>
              <span class="subtitle"> Tutustu kurssiin </span>
            </div>
          </div>
        </router-link>
        <ModulesFeed />
      </template>
    </div>
  </div>
</template>
<script>
import ModulesFeed from "@/views/ModulesFeed.vue";
export default {
  name: "AvailableCourses",
  components: {
    ModulesFeed,
  },
  computed: {
    availableCourses() {
      return this.$store.getters.availableCourses;
    },
  },
};
</script>
