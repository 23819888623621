import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { store } from "./store";

const firebaseConfig = {
  apiKey: "AIzaSyAXxrxcbitwjjhTYKmNT5-REIVzc4cxQ5U",
  authDomain: "choozio-12e27.firebaseapp.com",
  projectId: "choozio-12e27",
  storageBucket: "choozio-12e27.appspot.com",
  messagingSenderId: "754401768639",
  appId: "1:754401768639:web:6d9bbc356600accbdf420a",
  measurementId: "G-2TC4RF6S9J",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

firebase.getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        //check if user document exists
        firebase
          .firestore()
          .collection("userData")
          .doc(user.uid)
          .get()
          .then((doc) => {
            if (doc.exists) {
              store.dispatch("setUserState", user.uid);
            } else {
              firebase
                .firestore()
                .collection("userData")
                .doc(user.uid)
                .set({
                  name: user.email.split("@")[0],
                  uid: user.uid,
                  position: "Student",
                  current: null,
                  active: {},
                  completed: {},
                })
                .then(() => {
                  store.dispatch("setUserState", user.uid);
                })
                .catch((error) => {
                  console.error("Error writing document: ", error);
                });
            }
          })
          .catch((error) => {
            console.log("error getting doc" + error);
          });
      }
      unsubscribe();
      resolve(user);
    }, reject);
  });
};

export default firebase;
