export const dataModule3 = {
  id: "MmvcZaZrjBjoScIU4m4",
  title: "Moduuli 3: Hinnoittelu",
  intro: [
    "<ul>Tässä osiossa käymme läpi yrityksen kohtaamia hinnoittelupäätöksiä:<li>Millaisia hinnoittelumenetelmiä yritys voi käyttää hinnoitellessaan tuotteitaan tai palveluitaan?</li><li>Mitä tietoa yrittäjällä tulisi olla hinnoittelun perustana?</li><li>Miten hinnoittelussa tulisi ottaa huomioon yrityksen asiakkaalleen tuottama arvo, kustannukset ja kilpailevat tuotteet?</li></ul>",
    "Tämä osio tarjoaa sinulle analyyttisen lähestymistavan hinnoittelupäätöksissä menestymiseen!",
  ],
  video:
    "https://firebasestorage.googleapis.com/v0/b/eduvideo-e03eb.appspot.com/o/Moduuli_3_Johdatus_hinnoitteluun.mp4?alt=media",
  slides: [
    {
      type: "video",
      h: "Hinnoittelu",
      video:
        "https://firebasestorage.googleapis.com/v0/b/eduvideo-e03eb.appspot.com/o/Moduuli_3_Hinnoittelu_Osa_1.mp4?alt=media",
    },
    {
      type: "text",
      h: "Miksi hinnoittelu on tärkeää?",
      p: [
        "Hinnoittelulla voidaan keskeisesti vaikuttaa yritystoiminnan kannattavuuteen ja taloudelliseen asemaan",
        "Hinnoittelulla katetaan toiminnan kustannukset ja varmistetaan, että rahaa saadaan selvästi enemmän kuin mitä kustannuksia syntyy, jotta sitä jää myös voitonjakoa ja kasvua varten",
        "Asiakkaan arvolupaus - Mitä arvoa tuotteesi tai palvelusi asiakkaalle tuottaa ja miten tämä arvo suhteutuu siihen, mitä kilpailijasi tarjoavat?",
        '<i>Muista myös vanha sanonta: "Hullu ei ole se joka pyytää, vaan se joka maksaa"</i>',
      ],
    },
    {
      type: "text",
      h: "Tavallisimmat tavat lähestyä hinnoittelua",
      p: [
        "<ul>1. Kustannuspohjainen hinnoittelu<li>Kustannuspohjainen hinnoittelu lähtee liikkeelle kustannusten selvittämisestä.</li><li>Kustannusten oltua tiedossa, lisätään niiden päälle tavoitteena oleva kate.</li><li>Tyypillisesti toiminnan kaikki kustannukset pyritään kohdentamaan suoritteelle.</li><li>Tällä tyylillä tapahtuva hinnoittelu on suhteellisen nopeaa, mutta se ei ota huomioon kysyntää, markkinahintaa tai muita päätöksenteossa mahdollisesti olennaisia tekijöitä</li></ul>",
      ],
    },
    {
      type: "text",
      h: "Tavallisimmat tavat lähestyä hinnoittelua",
      p: [
        "<ul>2. Markkinalähtöinen hinnoittelu<li>Hinta määräytyy kilpailutilanteen perusteella.</li><li>Hinta tulee lähes annettuna, joten kustannuksilla ei ole merkitystä hinnoittelupäätökseen.</li><li>Kustannuksilla on kuitenkin merkitystä siinä, tuottaako yritys voittoa annetulla hintatasolla.</li></ul>",
      ],
    },
    {
      type: "text",
      h: "Tavallisimmat tavat lähestyä hinnoittelua",
      p: [
        "<ul>3. Asiakkaalle tuotetun arvon tai asiakkaan vaihtoehtojen mukaan hinnoittelu<li>Käytetään kun yrityksellä on tuote/palvelu, jolle ei löydy suoraa kilpailijan vastinetta.</li><li>Näin ollen markkinahintaa ei ole ja kustannuksiin perustuva hinnoittelu voisi olla huono vaihtoehto -> tuotteesta/palvelusta olisi mahdollista saada paljon kustannuksia parempi hinta! </li><li>Asiakkaan vaihtoehtoisen toimintamallin kustannus voi toimia hinnoittelupäätöksen lähtökohtana</li></ul>",
      ],
    },
    {
      type: "text",
      h: "Tavallisimmat tavat lähestyä hinnoittelua",
      p: [
        "<ul>4. Kermankuorintahinnoittelu<li>Hinta asetetaan ensin korkeaksi, kun kilpailu on vähäistä.</li><li>Kun kilpailu ajan mittaan kovenee, hintaa lasketaan</li><li>Jos yrityksen tuote/palvelu on ainutlaatuinen eikä vastaavia juuri ole, tämä menetelmä on mahdollinen.</li></ul>",
      ],
    },
    {
      type: "text",
      h: "Tavallisimmat tavat lähestyä hinnoittelua",
      p: [
        "<ul>5. Markkinoille tunkeutuminen<li>Hinta asetetaan ensin alas, jotta saadaan asiakkaat ostamaan yrityksen tuotteita/palveluita.</li><li>Kun asiakkaat ovat löytäneet tuotteen/palvelun, hintaa pyritään nostamaan.</li></ul>",
      ],
    },
    {
      type: "text",
      h: "Arvon haarukointi käytännössä",
      p: [
        "<ol><li>Etsi vastaava tuote tai palvelu - Mitä asiakkaat siitä maksavat?</li><li>Kilpailijoista erottuminen - Miten oma tuotteesi/palvelusi poikkeaa muista?</li><li>Määrittele erojen hintaa - Lisää hintaa sinulle edullisten erojen osalta ja vähennä epäedullisten</li><li>Kustannukset - Varmista, että näin määritetty arvo eli hinta kattaa kustannuksesi</li><li>Perustele - Kerro asiakkaalle, miksi hinta on hyväksyttävissä</li></ol>",
      ],
    },
    {
      type: "text",
      h: "Mitä tietoa olisi hyvä olla hinnoittelun pohjana? ",
      p: [
        "<ul><li>Kuinka paljon asiakkaat ovat valmiita maksamaan tuotteistamme tai palveluistamme ja mihin hintaan vastaavia tuotteita on tarjolla</li><li>Kuinka paljon tuotteita pystymme tietyllä hinnalla myymään ja mikä vaikutus hinnalla on kysyntään</li><li>Kuinka paljon asiakkaat ovat valmiita maksamaan tuotteistamme tai palveluistamme ja mihin hintaan vastaavia tuotteita on tarjolla</li><li>Mitkä ovat eri tuotteiden ja palveluiden kustannukset</li><li>Miten asiakkaat arvottavat myymiemme tuotteiden erityisiä ominaisuuksia</li></ul>",
      ],
    },
    {
      type: "img",
      h: "Minimihinta",
      img: "Moduuli_3_minimihinta.png",
      caption:
        "Minimihinnalla myytäessä yrityksen kokonaistulos jää tappiolliseksi",
    },
    {
      type: "img",
      h: "Omakustannushinta",
      img: "Moduuli_3_omakustannushinta.png",
    },
    {
      type: "img",
      h: "Voittotavoite",
      img: "Moduuli_3_voittotavoite.png",
      caption:
        "Lisäämällä tuotteen tai palvelun omakustannushintaan voittotavoite, saadaan tavoitteellinen myyntihinta, jolla katetaan sekä kaikki kustannukset että tuotetaan omistajalle korvaus sijoitetusta pääomasta ja otetusta riskistä",
    },
    {
      type: "text",
      h: "Eivätkö tuotteet käy päätetyillä hinnoilla kaupaksi?",
      p: [
        "Tällöin tuotteet voi kannattaa myydä jopa hinnalla, alittaa minimikalkyylin kustannuksen, jos vaihtoehto on se, että ne jäävät käsiin eikä niistä saada senttiäkään!",
      ],
    },
    {
      type: "text",
      h: "Piilokustannukset",
      p: [
        "<ul><li>Asiakaspalautukset</li><li>Asiakkaille maksettavat korvaukset</li><li>Asiakkaat jättävät laskunsa maksamatta</li><li>Tavaroiden rikkoutuminen, katoaminen, varkaudet</li></ul>",
      ],
    },
    {
      type: "text",
      h: "Kustannuslaskenta ≠ Hinnoittelu",
      p: [
        "<ul><li>Kustannuslaskennan tehtävänä on kertoa, mitä jonkun tuotteen tai palvelun tekeminen maksaa</li><li>Hinnoittelun tehtävänä on löytää tuotteelle tai palvelulle hinta, jolla yrityksen voitto maksimoidaan tai muut tavoitteet saavutetaan</li></ul>",
      ],
    },
    {
      type: "img",
      h: "Hinnanalennusten vaikutus tulokseen",
      img: "Moduuli_3_vaikutus_tulokseen.png",
    },
    {
      type: "text",
      h: "Kustannusperusteisen hinnoittelun ongelmat",
      p: [
        "<ul><li>Ei takaa yrityksen kannattavuutta, koska laskelmat täytyy aina tehdä jollain oletuksella myynnin volyymistä</li><li>Ei takaa suurinta mandollista voittoa</li></ul>",
      ],
    },
    {
      type: "text",
      h: "Yhteenveto",
      p: [
        "Hinnoittelulla on aivan keskeinen rooli yrityksen kannattavuuden varmistamisessa",
        "<ul>Mieti aina ensin:<li>Mitä arvoa tuotteesi tai palvelusi asiakkaalle tuottaa?</li><li>Kuinka se poikkeaa muista markkinoilla olevista?</li><li>Mitä asiakas olisi valmis maksamaan?</li><li>Varmista, että tämä hinta kattaa myös kustannuksesi</li></ul>",
        "<i>Hinnoittelulla on keskeinen rooli yrityksen kannattavuuden ja taloudellisen aseman varmistamisessa!</i>",
      ],
    },
  ],
  assignments: [
    {
      title: "<span class='color-accent'>Testaa osaamisesi: </span>Oikein vai väärin?",
      text: "Ovatko seuraavat väittämät oikein vai väärin?",
      type: "boolean",
      questions: [
        {
          q:
            "Jos asetamme hinnat siten, että ne kattavat kaikki yrityksen kustannukset, muuttuvat ja kiinteät, sekä tarvittavan voittomarginaalin kustannusten päälle, yritystoiminta on varmuudella kannattavaa.",
          a: 1,
        },
        {
          q:
            "Järkevä hinta voidaan määrittää yrityksen kustannuksiin perustuen laskemalla, kunhan huomioidaan kaikki yrityksen kustannukset",
          a: 1,
        },
        {
          q:
            "Vanha suomalainen sanonta ”Ei se ole hullu joka pyytää vaan se joka maksaa” on hyvä muistaa kun hinnoittelee tuotteitaan ja palveluitaan",
          a: 0,
        },
        {
          q:
            "Eri tilanteissa hinnoittelua kannattaa lähestyä eri tavoin eli ei ole olemassa yhtä oikeaa tapaa hinnoitella joka sopisi kaikille yrityksille kaikissa olosuhteissa",
          a: 0,
        },
        {
          q:
            "Hinnoittelussa olisi aina hyvä pohtia sitä, mitä arvoa tuotteemme tai palvelumme asiakkaalle tuottaa",
          a: 0,
        },
      ],
      options: ["Oikein", "Väärin"],
    },
    {
      title:
        "<span class='color-accent'>Harjoitustehtävä:</span> Aloittavan yrityksen hinnoittelu ja taloussuunnittelu",
      type: "text",
      text:
        "Vastaa seuraaviin kysymyksiin käyttämällä apunasi tämän tehtävän liitteenä olevia taulukoita. Pyyhkäise vasemmalle tutkiaksesi taulukoita",
      questions: [
        {
          h: "Kulubudjetti",
          q:
            "Laadi ensin alustava kulubudjetti 100% käyttöasteella. Laske paljonko siivoojien palkkoihin menisi vuositasolla, jos he työskentelevät kukin 8 tuntia per päivä, 220 päivää vuodessa. Samoin paljonko menisi matkakorvauksiin, omaan palkkaasi sekä kiinteisiin kuluihin.",
          /* attachments: [""], */
        },
        {
          h: "Kulubudjetti",
          q:
            "Laadi kulubudjetti eri käyttöasteilla, esim. 65%, 70%, 75%, 80% ja 85%. Oma palkkasi ja kiinteät toimistokulut eivät vaihtele käyttöasteen mukaan, sen sijaan siivoojille maksettavat korvaukset riippuvat tehtyjen tuntien määrästä.",
          /* attachments: [""], */
        },
        {
          h: "Tulosbudjetti",
          q:
            "Laadi alustava tulosbudjetti (eli mikä liikevaihdon pitäisi olla) olettamalla 75 % käyttöaste ja huomioimalla yrityksen ensimmäisen vuoden liikevoittotavoite 0 euroa. Edellisestä kulubudjettitaulukosta näet, paljonko kustannuksia 75% käyttöasteella syntyy. Jos voittotavoite on 0, liikevaihtoa tulee olla saman verran kuin on kuluja. Jos asetat myös voittotavoitteen, liikevaihdon tulee nousta vastaavasti.",
          /* attachments: [""], */
        },
        {
          h: "Tulosbudjetti: Matriisi",
          q:
            "Laadi matriisi, jossa tarkastelet eri myyntihintojen ja käyttöasteiden tuottamaa liikevaihtoa. Matriisi siis kertoo, millä siivoustunnin hinnalla (ilman ALV.) ja erilaisilla oletuksilla saavutettavasta käyttöasteesta päästään alustavan tulosbudjettisi liikevaihtoon.",
          attachments: [
            "<p>Laita esim. pystyakselille erilaisia tuntihintoja ja vaaka-akselille erilaisia käyttöasteita kuvaamaan myytyjä tunteja. Voit tässä vaiheessa vielä laittaa hinnat ilman ALV:ia. </p><p>Laske kuhunkin soluun, mikä olisi ko. tuntihinnan ja käyttöasteen tuottama myynti. Esim. jos asetat hinnaksi 20 € ja käyttöaste on 70 %, myyntiä syntyy 70% x myytävissä olevat tunnit x 20€. Myytävissä olevat tunnit saat laskemalla siivoojien määrän (8) ja kertomalla sen päivässä olevilla tunneilla (8) ja vuodessa olevien työpäivien määrällä (220). <p>Mikäli käyttöaste on 70%, mikä on veroton myyntihinta? Entä asiakashinta (lisää ALV verottomaan myyntihintaan)?</p>",
          ],
        },
        {
          h: "Tulosbudjetti",
          q: "Laadi lopullinen tulosbudjetti olettamalla 70 % käyttöaste.",
          attachments: [
            "<p>Liikevaihto = 70% X Myytävissä olevat tunnit x Myyntihinta.</p> <p>Myytävissä olevat tunnit saat laskemalla siivoojien määrän (8) ja kertomalla sen päivässä olevilla tunneilla (8) ja vuodessa olevien työpäivien määrällä (220).</p><p>Ota huomioon muuttuvien kustannusten käyttäytyminen käyttöasteen mukaan: <p>Mitkä vaikutukset tällä on muuttuvien kustannusten määrään? Entä asetettavaan myyntihintaan?</p>",
          ],
        },
      ],
    },
  ],
  glossary: {
    title: "Sanakirja: Hinnoittelu",
    words: {
      "Asiakkaan tuotteesta/palvelusta saama arvo":
        "Mitä arvoa tuote/palvelu asiakkaalle tuottaa ja miten tämä arvo suhteutuu siihen, mitä kilpailijat tarjoavat?",
      "Kiinteät kustannukset":
        "Kustannukset, jotka aiheutuvat joka tapauksessa, vaikka mitään ei myytäisi (myynnin volyymi 0)",
      Kustannuslaskenta:
        "EI ole sama kuin hinnoittelu! Kustannuslaskenta kertoo, mitä tuotteen/palvelun tuottaminen yritykselle maksaa. Vs. hinnoittelun tehtävä hinta, joka maksimoi yrityksen voiton/muut tavoitteet saavutetaan.",
      Käyttökate:
        "Voitto ennen poistoja, rahoituskuluja ja veroja. Saadaan kun liikevaihdosta vähennetään sitä vastaavat muuttuvat ja kiinteät kustannukset (pois lukien poistot, rahoituskulut ja verot).",
      Hinnoittelu:
        "Hinnan määrittäminen yrityksen tarjoamalle tuotteelle tai palvelulle. Hinnoittelun tehtävänä on löytää tuotteelle/palvelulle hinta, jolla voitto maksimoituu/muut tavoitteet saavutetaan. Hinnoittelulla voidaan vaikuttaa yrityksen taloudelliseen asemaan ja kannattavuuteen. Jotta yritys säilyy hengissä, hinnoittelun tulee kattaa kaikki toiminnasta aiheutuvat kustannukset. Jotta rahaa riittää myös kasvuun ja voiton jakoon, hintaa tulee saada selvästi enemmän kuin mitä kustannuksia syntyy. Lähdettäessä etsimään hintaa tuotteelle tai palvelulle, tulee aina ensin pohtia, mitä arvoa tuotteesi/palvelusi asiakkaalle tuottaa ja miten tämä arvo suhteutuu siihen, mitä tarjoavat!",
      Minimihinta:
        "Mikäli kustannuksena käsitellään vain muuttuvat kustannukset, saadaan minimihinta, joka tuotteesta tulisi saada, jotta myyty yksikkö ei tuottaisi tappiota. Vaikka mikään yksiköistä ei tuottaisi tappiota, kun myydään minimihinnalla yrityksen kokonaistulos jää tappiolliseksi, koska vuokrat, palkat jne. kustannukset (volyymista riippumattomat) tulee maksaa.",
      "Muuttuvat kustannukset":
        "Kustannukset, jotka muuttuvat tuotetun volyymin mukaan. Esimerkiksi tuotetun tuotteen raaka-ainekustannukset kasvavat per jokainen uusi tuotettu tuote.",
      "Omakustannushinta (täyskatteellinen)":
        "Kun minimihintaan lisätään kaikki toiminnan muut kustannukset, päädytään kunkin tuotteen täyskatteelliseen omakustannushintaan. Kustannusperusteisessa hinnoittelussa tähän yleensä lisätään voittotavoite, jolloin saadaan tavoitteena oleva myyntihinta -> katetaan kaikki kustannukset ja tuotetaan omistajalle korvaus sijoitetusta pääomasta ja otetusta riskistä.",
      Piilokustannukset:
        "Riskeistä johtuvat kustannukset, esim. asiakkaat saattavat valittaa, joista aiheutuu lisäkustannuksia, kun joudutaan maksamaan heille korvauksia. Tai toimituksessa rikkoutuneen tuotteen korvaaminen jne.",
    },
  },
};
