import { dataModule1 } from "./dataModule1";
import { dataModule2 } from "./dataModule2";
import { dataModule3 } from "./dataModule3";
import { dataModule4 } from "./dataModule4";
import { dataModule5 } from "./dataModule5";

export const dataCourses = {
  courses: [
    {
      id: "MmvcObp9rPkOKUbpfsY",
      title: "Yrittäjän talousosaaminen",
      text: [
        "<ul>Tämä kurssi tarjoaa sinulle vastauksia näihin kysymyksiin: <li>Miten voit suunnitella oman yrityksesi taloutta ja tulkita talouden raportteja?</li><li>Miten tehdä analyyttisia hinnoittelupäätöksiä tuotteille tai palveluillesi?</li><li> Mitkä ovat uuden työntekijän palkkaamisen kannattavuusvaikutukset?</li><li> Entä miten voit hallita yrityksesi käyttöpääomaa?</li></ul>",
        "Ymmärtämällä näitä keskeisiä talouden hallinnan keinoja yrityksesi voi saada toiminnastaan entistä enemmän irti ja parantaa kannattavuuttaan!",
      ],
      /* video: "Johdatus_yrittäjän_talousosaamiseen.mp4", */
      video:
        "https://firebasestorage.googleapis.com/v0/b/eduvideo-e03eb.appspot.com/o/Johdatus_yritta%CC%88ja%CC%88n_talousosaamiseen.mp4?alt=media&token=e8ee8645-8880-47d3-b03a-dda5bff76abb",
      modules: [
        dataModule1,
        dataModule2,
        dataModule3,
        dataModule4,
        dataModule5,
      ],
    }
  ],
};
