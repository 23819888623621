<template>
  <section class="frame scroll">
    <div class="content text-left">
      <h3>{{glossaryData.title}}</h3>
      <div class="content text-left" v-for="(value, item) in glossaryData.words" :key="item">
        <h4 class="color-accent">{{ item }}</h4>
        <p>{{ value }}</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    courseId() {
      return this.$store.getters.currentId;
    },
    moduleId() {
      return this.$route.params.moduleid;
    },
    glossaryData() {
      return this.$store.getters.moduleData(this.moduleId)
        .glossary;
    },
  },
};
</script>