<template>
  <div class="screen screen-courses-feed screen-full">
    <div class="content-wrap">
      <div class="page-title-container" v-if="this.$route.name === 'Course modules'" >
        <h2 class="page-title" >{{ courseInfo.title }}</h2>
      </div>
      <template v-for="(item,n) in courseModules" :key="item.title">
        <router-link :to="'/module/' + item.id" class="neo-container-mid">
          <div class="information-container">
            <span class="counter counter-primary">{{ n + 1 }}</span>
            <div class="wrap">
              <span class="title"> {{ item.title }}</span>
              <span class="subtitle"> Aloita moduuli </span>
            </div>
          </div>
        </router-link>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: "Modules feed",
  components: {},
  computed: {
    courseId() {
      return "MmvcObp9rPkOKUbpfsY";
    },
    courseInfo() {
      return this.$store.getters.courseData(this.courseId);
    },
    courseModules() {
      return this.$store.getters.courseData(this.courseId).modules;
    },
  },
};
</script>
