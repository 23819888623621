<template>
  <section class="frame">
    <div class="content"><h2>Tapahtui virhe!</h2></div>
    <div class="content"><p>Kurssimateriaali ei ole saatavilla.</p></div>
    <div class="content">
      <button @click="$router.go(-1)" class="btn btn-primary"
        >Palaa takaisin</button
      >
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style>
</style>