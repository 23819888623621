import { createStore } from "vuex";
import { dataCourses } from "../dataStructure/dataCourses";
import createPersistedState from "vuex-persistedstate";

import firebase from "../firebaseInit";

export const store = createStore({
  plugins: [createPersistedState({ storage: window.sessionStorage })],
  state: {
    user: {},
    courses: dataCourses.courses,
  },
  getters: {
    /* BEGIN USER DATA GETTERS */
    userState(state) {
      return state.user;
    },
    currentUser(state) {
      return state.user.uid;
    },
    currentModule(state) {
      if (state.user.current !== "" || state.user.current !== null) {
        return state.user.current;
      }
    },
    currentSlide: (state) => (id) => {
      if (state.user.active[id] !== undefined) {
        return state.user.active[id].slide;
      } else return 0;
    },
    currentSlideCount(state) {
      if (state.user.active[state.user.current] !== undefined) {
        return state.user.active[state.user.current].slideCount;
      } else return 0;
    },
    currentPref(state) {
      if (state.user.active[state.user.current] !== undefined) {
        return state.user.active[state.user.current].pref;
      } else return null;
    },
    completedCourses(state) {
      return state.user.completed;
    },
    /* END USER DATA GETTERS */

    /* BEGIN COURSE DATA GETTERS */
    availableCourses(state) {
      return state.courses;
    },
    courseData: (state) => (id) => {
      return state.courses.find((course) => course.id === id);
    },
    moduleData: (state) => (moduleid) => {
      for (let course of state.courses) {
        for (let module of course.modules) {
          if (module.id === moduleid) {
            return module;
          }
        }
      }
    },
    /* END COURSE DATA GETTERS */
  },
  mutations: {
    //push user data to state
    setUserState(state, payload) {
      state.user = payload;
    },
  },
  actions: {
    //push user data from firestore to user state
    //snapshot syncs changes from firestore to state
    setUserState({ commit }, uid) {
      firebase
        .firestore()
        .collection("userData")
        .doc(uid)
        .onSnapshot((doc) => {
          commit("setUserState", doc.data());
        });
    },
    setActive({ getters, dispatch }, { id, name }) {
      let courseExists = false;
      for (const obj in getters.userState.active) {
        if (obj === id) {
          courseExists = true;
        }
      }

      if (!courseExists) {
        firebase
          .firestore()
          .collection("userData")
          .doc(getters.currentUser)
          .update({
            [`active.${id}`]: {
              name: name,
              slide: 0,
              slideCount: 0,
              pref: "text",
              answers: [],
            },
          })
          .then(() => {
            dispatch("setCurrent", id);
          })
          .catch((error) => {
            console.error("Error writing document: ", error);
          });
      }
    },
    //set latest opened module id to current field
    setCurrent({ getters }, id) {
      firebase
        .firestore()
        .collection("userData")
        .doc(getters.currentUser)
        .update({
          current: id,
        });
    },
    //set module preference to active module map
    setPref({ getters }, pref) {
      firebase
        .firestore()
        .collection("userData")
        .doc(getters.currentUser)
        .update({
          [`active.${getters.currentModule}.pref`]: pref,
        });
    },
    setSlideCount({ getters }, { id, slideCount }) {
      firebase
        .firestore()
        .collection("userData")
        .doc(getters.currentUser)
        .update({
          [`active.${id}.slideCount`]: slideCount,
        });
    },
    setCurrentSlide({ getters }, { id, currentSlide }) {
      firebase
        .firestore()
        .collection("userData")
        .doc(getters.currentUser)
        .update({
          [`active.${id}.slide`]: currentSlide,
        });
    },
    //push answer to firebase active array
    setAnswer({ getters }, answerObj) {
      firebase
        .firestore()
        .collection("userData")
        .doc(getters.currentUser)
        .update({
          [`active.${getters.currentModule}.answers`]: firebase.firestore.FieldValue.arrayUnion(
            answerObj
          ),
        });
    },
  },
  modules: {},
});
