<template>
  <div class="progress">
    <div
      class="progress-bar"
      role="progressbar"
      :aria-valuenow="moduleProgress"
      aria-valuemin="0"
      aria-valuemax="100"
      :style="{ width: moduleProgress }"
    ></div>
  </div>
</template>

<script>
export default {
  name: "Progress Bar",
  computed: {
    moduleProgress() {
      if (
        this.$store.getters.currentModule !== null &&
        this.$store.getters.userState.active[this.$store.getters.currentModule]
        //this.$store.getters.userState.active[this.$route.params.moduleid]
      ) {
        return (
          (this.$store.getters.currentSlide /
            this.$store.getters.currentSlideCount) *
            100 +
          "%"
        );
      } else {
        return "0%";
      }
    },
  },
};
</script>

<style>
</style>