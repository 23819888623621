<template>
  <div class="screen screen-main-feed screen-full">
    <div class="content-wrap">
      <section class="hero-avatar text-center">
        <div class="wrap-avatar">
          <img alt="User avatar" src="../assets/aalto-profile-logo.png" />
        </div>
        <div class="wrap-title">
          <h3>{{ user.name }}</h3>
          <span class="subtitle">{{ user.position }}</span>
        </div>
      </section>
      <section class="block-nav">
        <div class="row">
          <div class="col">
            <button class="btn btn-neo btn-complex" @click="changeFeed(0)">
              <span class="counter counter-secondary"
                ><svg
                  width="7"
                  height="10"
                  viewBox="0 0 7 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M5.5 1L1.5 5L5.5 9" stroke="#fff" stroke-width="3" />
                </svg>
              </span>
              <div class="wrap">
                <span class="title"> Aktiiviset </span>
                <span class="subtitle"> Katso kaikki </span>
              </div>
            </button>
          </div>
          <div class="col">
            <button class="btn btn-neo btn-complex" @click="changeFeed(2)">
              <span class="counter counter-primary"
                ><svg
                  width="7"
                  height="10"
                  viewBox="0 0 7 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    transform="scale (-1, 1)"
                    transform-origin="center"
                    d="M5.5 1L1.5 5L5.5 9"
                    stroke="#fff"
                    stroke-width="3"
                  />
                </svg>
              </span>
              <div class="wrap">
                <span class="title"> Moduulit </span>
                <span class="subtitle"> Katso kaikki </span>
              </div>
            </button>
          </div>
        </div>
      </section>
      <section class="active-course">
        <router-link
          to="/course/MmvcObp9rPkOKUbpfsY"
          class="course-link course-1"
        >
          <span class="title">Yrittäjän talousosaaminen</span>
          <span class="subtitle">Tutustu kurssiin</span>
        </router-link>
      </section>
    </div>
  </div>
</template>
<script>
import "@google/model-viewer";
export default {
  name: "MainFeed",
  components: {},
  methods: {
    changeFeed(slide) {
      this.$emit("changeFeed", slide);
    },
  },
  computed: {
    user() {
      return this.$store.getters.userState;
    },
    current() {
      if (this.$store.getters.userState.current) {
        return this.$store.getters.userState.current;
      } else return null;
    },
  },
};
</script>
