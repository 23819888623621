<template>
  <div class="content">
    <h3 class="color-accent">{{ slide.h }}</h3>
  </div>
  <div class="content">
    <div class="video-square">
      <div class="card-img">
        <div v-if="!isPlaying" class="video-overlay">
          <button @click="toggleVideo()" class="btn">
            <img src="../assets/play_overlay.png" />
          </button>
        </div>
        <video
          controls
          controlsList="nodownload noremoteplayback"
          webkit-playsinline
          playsinline
          poster="../assets/videothumb.png"
          ref="video"
        >
          <!-- <source :src="require(`../assets/video/${slide.video}`)" /> -->
          <source :src="slide.video" />
        </video>
      </div>
      <div class="card-body">
        <h4 class="card-title">{{ slide.h }}</h4>
        <p>
          {{ slide.text }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FrameSlideVideo",
  data() {
    return {
      isPlaying: false,
      btnText: "Katso",
    };
  },
  props: {
    slide: Object,
  },
  methods: {
    toggleVideo() {
      if (this.isPlaying) {
        this.btnText = "Katso";
        this.stopVideo();
        this.controls = "controls";
      } else {
        this.btnText = "Keskeytä";
        this.playVideo();
        this.controls = " ";
      }
    },
    playVideo() {
      if (this.$refs.video) {
        this.$refs.video.play();
        this.isPlaying = true;
      }
    },
    stopVideo() {
      if (this.$refs.video) {
        this.$refs.video.pause();
        this.isPlaying = false;
      }
    },
  },
};
</script>

<style>
</style>