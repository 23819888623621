<template>
  <div class="content-wrap">
    <div class="page-title-container">
      <h2 class="page-title text-center">Aktiiviset moduulit</h2>
    </div>
    <template v-if="activeModules === null">
      <router-link to="/course/MmvcObp9rPkOKUbpfsY" class="neo-container-mid">
        <div class="information-container">
          <span class="counter counter-secondary"> i </span>
          <div class="wrap">
            <span class="title"> Yrittäjän talousosaaminen </span>
            <span class="subtitle"> Tutustu kurssiin </span>
          </div>
        </div>
      </router-link>
    </template>
    <template v-else>
      <template v-for="(module, n) in activeModules" :key="module[0]">
        <router-link :to="'/module/' + module[0]" class="neo-container-mid">
          <div class="information-container">
            <span class="counter counter-secondary"> {{ n + 1 }} </span>
            <div class="wrap">
              <span class="title"> {{ module[1].name }}</span>
              <span class="subtitle"> Jatka moduulia </span>
            </div>
          </div>
        </router-link>
      </template>
    </template>
  </div>
</template>
<script>

export default {
  name: "ActiveCourses",
  computed: {
    activeModules() {
      if (
        this.$store.getters.userState.active &&
        Object.keys(this.$store.getters.userState.active).length > 0
      ) {
        return Object.entries(this.$store.getters.userState.active);
      } else {
        return null;
      }
    },
  },
};
</script>
