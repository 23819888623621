<template>
  <swiper
    @swiper="onSwiper"
    :slides-per-view="1"
    :space-between="0"
    :initialSlide="1"
    :pagination="{ clickable: false }"
  >
    <swiper-slide>
      <div class="content-wrap">
        <div class="page-title-container">
          <h2 class="page-title">Kurssit</h2>
        </div>
        <ActiveCourses /></div
    ></swiper-slide>
    <swiper-slide><MainFeed @changeFeed="changeFeed" /></swiper-slide>

    <swiper-slide><AvailableCourses /></swiper-slide>
  </swiper>
</template>
<script>
import MainFeed from "@/views/MainFeed.vue";
import ActiveCourses from "@/views/ActiveCourses.vue";

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import AvailableCourses from "./AvailableCourses.vue";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

export default {
  name: "Dashboard",
  components: {
    ActiveCourses,
    MainFeed,
    Swiper,
    SwiperSlide,
    AvailableCourses,
  },
  data() {
    return {
      mainSwiper: null,
    };
  },
  mounted() {},
  methods: {
    onSwiper(swiper) {
      this.mainSwiper = swiper;
    },
    changeFeed(slide) {
      this.mainSwiper.slideTo(slide);
    },
  },
};
</script>
