export const dataModule4 = {
  id: "MmvcaXmdThAYlutqkBI",
  title: "Moduuli 4: Palkkauksen kustannukset",
  intro: [
    "<ul>Tässä osiossa käymme läpi ulkopuolisen työntekijän palkkaukseen liittyviä asioita: <li>Miten voi arvioida, kannattaako työntekijän palkkaaminen?</li><li>Millaisia viranomaisvelvollisuuksia palkkojen maksuun liittyy?</li><li>Mitä muita kustannuksia palkan lisäksi tulee maksettavaksi?</li><li>Mitä velvollisuuksia työntekijän paikkaamiseen liittyy ja mitkä ovat työntekijän palkkaamisen kannattavuusvaikutukset?</ul>",
  ],
  video:
        "https://firebasestorage.googleapis.com/v0/b/eduvideo-e03eb.appspot.com/o/Moduuli_4_Johdatus_palkkauksen_kustannuksiin.mp4?alt=media",
  slides: [
    {
      type: "video",
      h: "Johdatus palkkauksen kustannuksiin",
      video: "https://firebasestorage.googleapis.com/v0/b/eduvideo-e03eb.appspot.com/o/Moduuli_4_Palkkauksen_kustannukset.mp4?alt=media",
    },
    {
      type: "text",
      h: "Miksi on tärkeää ymmärtää palkkauksen kustannuksia?",
      p: [
        "<ul><li>Yritykseen tarvitaan monenlaista osaamista, jotta se menestyy. Valitsemalla oikeat työntekijät oikeaan aikaan, lisääntyy menestymisen mahdollisuus huomattavasti.</li><li>Työntekijöistä aiheutuu kustannuksia, mutta paikkaamista kannattaa ajatella investointina, josta odotetaan tuottoja tulevaisuudessa.</li><li>Jotta osataan arvioida, milloin kannattaa palkata lisää työvoimaa, tulee ymmärtää työntekijöistä aiheutuvat kustannukset.</li><li>Kun yrittäjä palkkaa ulkopuolisen työntekijän yritykseensä, hänestä tulee työnantaja. Työnantajalla on monenlaisia velvoitteita, ja on tärkeää tuntea nämä velvoitteet ja hoitaa ne asianmukaisesti.</li></ul>"
      ],
    },
    {
      type: "text",
      h: "Työsopimus",
      p: [
        "<ul><li>Työntekijän ja työnantajan kannattaa aina tehdä työsopimus, jossa sovitaan työtehtävistä, maksettavista palkoista sekä muista ehdoista ja eduista.</li><li>Työsopimuksen voi solmia suullisesti, mutta kannattaa tehdä sopimus aina kirjallisesti, jotta mahdollisilta väärinymmärryksiltä vältytään.</li></ul>"
      ],
    },
    {
      type: "text",
      h: "Palkka",
      p: [
        "Palkka on työntekijälle maksettava korvaus tehdystä työstä. Palkkaa maksetaan työsuhteen perustella, niistä asioista, joita tekemään työntekijä on palkattu. Myös yrittäjä voi nostaa yrityksestään palkkaa (pois lukien toiminimellä toimivat elinkeinonharjoittajat).",
        "Palkan määrä sovitaan työntekijän kanssa työsopimusta solmittaessa. Jos alalla on  ns. yleissitova työehtosopimus TAI jos yrityksesi kuuluu työnantajaliittoon, pitää työsuhteessa noudattaa työehtosopimuksen ehtoja.",
        "Palkan muodolla voidaan varmistaa palkkauksen taloudellisuutta (maksu tuloksesta) ja myös kannustaa työntekijää keskittymään yrityksen kannalta tärkeisiin asioihin!",
        "Maksamalla osan palkasta tulokseen sidonnaisena yrittäjä voi muuntaa yrityksen kustannuksia kiinteistä muuttuviin ja vastaamaan paremmin yrityksen maksukykyä!",
      ],
    },
    {
      type: "text",
      h: "Palkka",
      p: [
        "Työehtosopimuksissa on määräyksiä mm. työajasta, palkkauksesta, vuosilomista jne. On tarkeää ajatella, että tyontekijan palkkaamisen tulee parantaa kannattavuutta.",
        "<ol>Palkkaa voidaan maksaa joko: <li>Työpaikalla vietetyn ajan perusteella = AIKAPALKKA , tai</li> <li> aikaansaadun työmäärän perusteella = SUORITUSPALKKA</li></ol>",
        "Aikapalkkoja ovat tuntipalkka ja kuukausipalkka. Suorituspalkkoja esimerkiksi urakkapalkka ja erilaiset provisiot tai bonukset. Lisäksi voidaan käyttää erilaisia aika- ja suorituspalkan yhdistelmiä.",
      ],
    },
    {
      type: "text",
      h:
        "Esimerkki 1: Paljonko lisämyyntiä tarvitaan, että myyjän palkkaus kannattaa?",
      p: [
        "Jos palkkaat yritykseesi myyjän 60 000 euron kustannuksella vuodessa, paljonko yrityksesi tarvitsee lisää myyntiä?",
        "Oletetaan, että yrityksen kate (eli liikevaihto vähennettynä myyntiä vastaavilla kuluina) on 40%.",
        "Uuden työntekijän kustannukset 60 000 euroa vuodessa, sisältäen kaikki sivukulut.",
        "Yrityksen kate 40% on liikevaihdosta.",
        "Lisämyynnin on oltava yli 150 000 €! (tästä kate 40% = 60 000 €)",
        "Lisämyynnin on oltava 150 000 euroa, sillä 40% 150 000:sta on 60 000 euroa, eli myyjästä koituvan lisäkustannuksen verran!",
      ],
    },
    {
      type: "text",
      h: "Luontoisedut",
      p: [
        "Rahapalkan lisäksi työntekijälle voidaan antaa osa palkasta erilaisina luontoisetuina. Luontoisetu tarkoittaa muuna hyödykkeenä kuin rahana saatua korvausta. Tavallisimpia luontoisetuja ovat asuntoetu, autoetu, lounasetu ja puhelimet.",
        "Luontoisedussa edellytetään aina, että työnantaja maksaa ulkopuoliselle, kolmannelle osapuolelle korvausta työntekijän saamasta edusta. Luontoisetu on työntekijälle palkkaa, ja häneltä pidätetään saadusta edusta veroa.",
        "Yritykselle luontoisedut ovat kuluja (esim. puhelinedusta tulee työnantajalle kulua puhelinlaskujen muodossa, autoedusta auton ostohinta tai kuukausittaiset leasing-maksut) ja työnantaj ayritys maksaa luontoiseduista myös palkan sivukulumaksut.",
        "<i>Millaiset edut motivoisivat sinua? Mitkä motivoisivat työntekijöitäsi? Työntekijälle mieluisat luontoisedut säästävät rahaa, kun hän voi saada ne käyttöönsä edullisemmin kuin itse hankkimalla!</i>",
      ],
    },
    {
      type: "text",
      h: "Verot ja vakuutuksiin liittyvät maksut",
      p: [
        "Työntekijän kanssa sovittua palkkaa ei makseta kokonaisuudessaan työntekijälle, vaan palkasta vähennetään erilaisia verottajalle ja vakuutuksiin liittyviä maksuja.", 
        "Työnantajan on aina pidätettävä työntekijälle maksettavasta palkasta ennakonpidätys eli vero.", 
        "Pidätetty vero tilitetään edelleen Verohallinnolle samaan tapaan kuin arvonlisäverot. Pidätettävän veron määrä selviää työntekijän verokortista.", "Työntekijän on siis ennen palkanmaksua toimitettava verokorttinsa palkanlaskentaan.",
      ],
    },
    {
      type: "text",
      h: "Ennakonpidätyksen lisäksi työntekijän palkasta on aina pidätettävä:",
      p: [
        "<ol>Ennakonpidätyksen lisäksi työntekijän palkasta on aina pidätettävä:<ol><li>Työeläkemaksu (yleensä TyEL-maksu): Työnantajayrityksen on otettava TYEL-vakuutus heti, jos palkkaa yhdenkin 17-67 vuotiaan työntekijän. Tyel-maksu on noin 25 % työntekijöille maksettavista ennakonpidätyksen alaisista palkoista. Tästä maksusta osa  pidätetään työntekijältä palkasta. Loppuosa maksusta (noin 18 %) jää yrityksen maksettavaksi. Yrittäjän omasta palkasta ei makseta työeläkemaksua, vaan yrittäjän oma eläketurva perustuu yrittäjäeläkkeeseen (YEL).</li></ol>"
      ],
    },
    {
      type: "text",
      h: "Ennakonpidätyksen lisäksi työntekijän palkasta on aina pidätettävä:",
      p: [
        "<ol start='2'><li>Työvakuutusmaksu, jonka työnantajan maksuosuus on noin puoli prosenttia ennakonpidätyksen alaisista palkoista. Jos yritys maksaa palkkoja yli 2 milj. euroa vuodessa, maksuprosentti suurenee. Lisäksi työntekijältä pidätetään palkasta työntekijän oma osuus työttömyysvakuutusmaksusta. Molemmat, siis työntekijän osuus ja työnantajayrityksen maksuosuus, tilitetään työttömyysvakuutus-rahastolle. Työttömyysvakuutusmaksua ei peritä alle 17-vuotiailta eikä yli 65-vuotiailta. Myöskään yrittäjäasemassa olevalta ei peritä tyva-maksua.</li></ol>"
      ],
    },
    {
      type: "text",
      h: "Ennakonpidätyksen lisäksi työntekijän palkasta on aina pidätettävä:",
      p: [
        "<ol start='3'><li>Sairausvakuutusmaksu (sava, aikaisemmin sotu): Tämän maksaa työnantajayritys yksin, eli ei pidätetä mitään työntekijän palkasta. Sairausvakuutusmaksu vaihtelee vuosittain, vuonna 2020 se on 1,34 % ennakonpidätyksen alaisista palkoista.</li><li>Työnantajayrityksen on myös velvollisuus vakuuttaa työntekijänsä. Pakollisia  vakuutuksia ovat tapaturmavakuutus ja ryhmähenkivakuutus. Nämä vakuutusmaksut määräytyvät  maksettujen palkkojen perusteella. Maksuprosentit ja tulorajat määräytyvät työn luonteen mukaan ja vaihtelevat hieman vuosittain. Yhteensä näiden vakuutusten maksut ovat yleensä alle prosentin luokkaa maksetuista palkoista</li></ol>",
      ],
    },
    {
      type: "text",
      h: "Työntekijän palkkaamisen kokonaiskustannukset",
      p: [
        "<ul><p>1000 euron palkasta aiheutuu työnantajayritykselle noin 1200 euron välitön kustannus. Tässä ei kuitenkaan ole vielä huomioitu kaikkia kustannuksia.</p> <p>Kun lasketaan työntekijän palkkaamisen kokonaiskustannuksia, huomioidaan edellä kerrottujen maksujen lisäksi kustannuksena myös:</p><li>työntekijän vuosiloma-ajan palkat</li><li>mahdolliset sairauslomat ja perhevapaat</li><li>mahdolliset työajanlyhennysvapaat (ns. Pekkaset)</li><li>koulutuspäivät yms. päivät, jolloin työntekijä ei ole varsinaisessa työssään</li><li>työntekijöiden työvaatteiden hankinta</li></ul>",
        "Nämä kaikki sivukulut huomioiden todellinen työnantajan kustannus on 1,4 tai jopa 1,6 -kertainen maksettavaan palkkaan nähden. Siis jos esim. työntekijä saa palkkaa 1 000 euroa kuukaudessa, työnantajalle aiheutuu siitä noin 1 400-1 600 euron kokonaiskustannukset.",
      ],
    },
    {
      type: "text",
      h: "Työntekijän palkkaamisen kokonaiskustannukset",
      p: [
        "Edellä mainitut pakolliset työnantajamaksut aiheuttavat yhteensä reilut 20% kustannukset maksettavan paikan päälle.",
        "Työntekijän palkka   (1 000 €)",
        "Tyel-maksu n. 18%	  (180€)",
        "Tyva-maksu n. 0,5%   (5€)",
        "SaVa-maksu n. 1%     (10€)",
        "Työtapaturma- ja ryhmäh. vak. n. 1% (10€)",
        "",
        "Työnantajan kustannus	  1 205 €",
      ],
    },
    {
      type: "text",
      h: "Esimerkki 2: Onko myyjän palkkaaminen kannattavaa",
      p: [
        "Palkkakulut <span class='color-accent'>40 000 €</span><br>Kustannukset <span class='color-accent'>60 000 €</span>",
        "Ollakseen kannattava investointi, tulee tuoda yritykselle katetta 60 000 euron edestä, eli hänen työpanoksensa tuottojen ja kulujen erotuksen tulee olla positiivinen.",
        "Kun myyjän palkka on 40 000 euroa, kokonaiskustannukset sivukuluineen ovat 60 000 euroa. Myyjän tulee tuoda yritykselle vähintään 60 000 euroa, jotta saadaan kokonaiskustannukset katettua.",
        "Tällöin yritykselle ei kuitenkaan jää mitään käteen, sillä myyjän palkkaamisen hänen työpanoksensa tuottojen ja kustannukset saadaan vain katettua hänen tuomallaan katteella. Ollakseen todella tuottava investointi, katetta tulee siis kertyä yli 60 000 euroa.",       
      ],
    },
    {
      type: "text",
      h: "Velvollisuudet",
      p: [
        "Palkkaa maksettaessa on tunnettava paljon eri lakeihin ja sopimuksiin littyviä säädöksiä. Lisäksi työnantajalla on Verottajan suuntaan velvollisuuksia, kuten ilmoitukset Tulorekisteriin jokaisen palkanlaskun jälkeen ja työntekijöiltä palkasta pidätettyjen verojen ja maksettavien sairasvakuutusmaksujen tilittäminen kuukausittain Verohallintoon.",
        "Usein onkin järkevää ulkoistaa palkanlaskenta siihen perehtyneelle palveluntarjoajalle, ainakin jos yrittäjallä itsellään ei ole osaamista tai erityistä kiinnostusta palkanlaskennan kiemuroista.",
      ],
    },
    {
      type: "text",
      h: "Muuta huomioitavaa palkoista",
      p: [
        "Kannattaa huomioida ja mahdollisuuksien mukaan välttää niitä tilanteita, joissa työn teettäminen tulee erityisen kalliiksi.", 
        "<ul>Näitä ovat: <li>Normaalin työajan (8 tuntia päivässäja 40 tuntia viikossa) ylittävä työ, koska siitä on maksettava ylityökorvauksena vähintään 50% korkeampi tuntipalkka.</li><li>Sunnuntaina tai muuna pyhäpäivänä tehdystä työstä on aina maksettava 100% sunnuntaityökorvaus, siis käytännössä kaksinkertainen palkka!</li><li>Eri työehtosopimuksissa voi olla myös muita ehtoja tilanteista, joissa on maksettava korotettua palkkaa (esim. iltatyöt, yötyöt).</li></ul>",
        "Varmista siis erityisesti näissä tilanteissa, että liikevaihtoa ja lisäarvoa asiakkaalle syntyy niin paljon, että työntekijä kannattaa pitää töissä kustannuksista huolimatta.",
        "Vaikka työntekijän palkkaamisen pitää olla yrityksen näkökulmasta kannattavaa, työnantajan kannattaa pyrkiä myös työntekijän kannalta hyviin työolosuhteisiin. Henkilöstön hyvinvointiin kannattaa satsata, sillä tämä maksaa takaisin ennemmin tai myöhemmin. ",
      ],
    },
    {
      type: "text",
      h: "Tiivistelmä",
      p: [
        "Yritys tarvitsee menestyäkseen paljon osaamista, ja yksi tapa hankkia tätä osaamista on palkata työntekijoitä. Tämä kannattaa tehdä tietoisesti, taloudellisesti ajatellen.",
        "On tärkeää ymmärtää, että henkilön palkkaaminen tarkoittaa vähintään 1,5-kertaisia kuluja bruttopalkkaan verrattuna. Hyvä työntekijä on siitä huolimatta hyvä investointi!",
      ],
    },
  ],
  assignments: [
    {
      title: "<span class='color-accent'>Testaa osaamisesi 1:</span> Oikein vai väärin? Työnantajan sivukulut",
      text: "Ovatko seuraavat väittämät oikein vai väärin?",
      type: "boolean",
      questions: [
        {
          q: "Verot ovat pakollinen työnantajan sivukulu.",
          a: 1,
        },
        {
          q: "Tapaturmavakuutusmaksu on pakollinen työnantajan sivukulu.",
          a: 0,
        },
        {
          q: "Eläkevakuutusmaksu on pakollinen työnantajan sivukulu.",
          a: 0,
        },
        {
          q: "Henkilökunnan terveydenhoito on pakollinen työnantajan sivukulu.",
          a: 1,
        },
        {
          q:
            "Työntekijän vapaa-ajan vakuutus on pakollinen työnantajan sivukulu.",
          a: 1,
        },
        {
          q: "Sairausvakuutusmaksu on pakollinen työnantajan sivukulu.",
          a: 0,
        },
        {
          q: "Työttömyysvakuutusmaksu on pakollinen työnantajan sivukulu.",
          a: 0,
        },
      ],
      options: ["Oikein", "Väärin"],
    },
    {
      title:
        "<span class='color-accent'>Testaa osaamisesi 2:</span> Palkkaamisen ilmoitus- ja maksuvelvollisuus",
      text:
        "Jos työntekijälle on maksettu palkka 28.2.2020, mitä ilmoitus- ja maksuvelvollisuuksia siitä seuraa ja millä aikataululla? Valitse oikeat vastaukset yhdistämällä tekstiin.",
      type: "single",
      questions: [
        {
          q: "Ilmoitus Tulorekisteriin pitää lähettää  ______.",
          options: [
            "laskun mukaan",
            "viimeistään 12.4",
            "viimeistään 5.3",
            "viimeistään 12.3",
          ],
          a: 2,
        },
        {
          q:
            "Työttömyysvakuutusmaksu maksetaan ______ sieltä tulevan laskun mukaan.",
          options: [
            "vakuutusyhtiölle",
            "ammattiliitolle",
            "Kelalle",
            "Työllisyysrahastolle",
          ],
          a: 3,
        },
        {
          q:
            "Eläkevakuutusmaksu maksetaan eläkevakuutusyhtiölle yleensä vakuutusyhtiön ______ tai voidaan myös sopia että yritys maksaa ______",
          options: [
            "suoramaksuna",
            "tilikauden päättymispäivänä",
            "luottokortilla",
            "laskun mukaan",
          ],
          a: [3,0]
        },
        {
          q:
            "Ennakonpidätys eli palkasta pidätetty veron osuus ja sairausvakuutusmaksu on maksettava Verohallinnolle ______.",
          options: ["viimeistään 5.3", "viimeistään 9.6", "viimeistään 12.3"],
          a: 2,
        },
      ],
    },
    {
      title: "<span class='color-accent'>Harjoitustehtävä:</span> Työntekijän palkkaamisen kannattavuus",
      type: "text",
      text:
        "Vastaa seuraaviin kysymyksiin käyttämällä apunasi tämän tehtävän liitteenä olevia taulukoita. Pyyhkäise vasemmalle tutkiaksesi taulukoita",
      questions: [
        {
          h: "Kannattaako palkata uusi siivooja?",
          q:
            "Siivousalan yrittäjä miettii uuden työntekijän palkkaamista. Palkkaa uudelle kokoaikaiselle siivoojalle pitäisi maksaa 2000 euroa/kk. Asiakkailta laskutetaan siivouksesta 40€/tunti + alv. Yhtä laskutettavaa työtuntia kohti syntyy tarvike- matka- ym. kiinteitä kuluja noin 5 €. Kuinka paljon lisämyyntiä vähintään pitäisi kertyä, että uusi työntekijä kannattaa palkata?",
        },
        {
          h: "Millainen laskutushinnan pitäisi olla, että kannattaa palkata? ",
          q:
            "Smart AI Oy tarjoaa liiketoiminnan kehittämiseen liittyviä neuvonta- ja asiantuntijapalveluita pk-yrityksille. Yrityksessä työskentelee tällä hetkellä vain yrittäjä itse, mutta koska kysyntää neuvonnalle on paljon, yrittäjä harkitsee ensimmäisen työntekijän palkkaamista.  Uuden työntekijän palkkakustannus olisi arvion mukaan kaikkine sivukuluineen noin 60 000€/vuosi ja yrittäjän kokemuksen mukaan konsulttityöstä pystyy laskuttamaan noin 90-100 tuntia kuukaudessa, muu aika menee asiakasyhteydenpitoon, hallinnollisiin töihin, omaan kouluttautumiseen yms.  Paljonko uuden työntekijän tekemästä työstä pitäisi veloittaa asiakkailta, että palkkaus näillä ehdoilla kannattaisi?",
        },
      ],
    },
  ],
  glossary: {
    title: "Sanakirja: Palkkauksen kustannukset",
    words: [
      {
        "Ennakonpidätys eli vero":
          "Työnantajan tehtävänä on pidättää työntekijälle maksetusta palkasta ennakonpidätys eli vero. Pidätetty vero tilitetään ALV:n tapaan edelleen Verohallinnolle. Työntekijän verokortti kertoo pidätettävän veron määrän.",
        Henkilösivukulut:
          "Jaetaan eläkekuluihin ja muihin henkilösivukuluihin. Muut henkilösivukulut ovat sosiaaliturvamaksut, tapaturmavakuutusmaksut, ryhmähenkivakuutusmaksut ja työttömyysvakuutusmaksut.",
        "Ilmoitus Tulorekisteriin":
          "Työnantajan velvoite tehdä ilmoitus verottajalle jokaisen palkanmaksun jälkeen",
        Kate:
          "= liikevaihto - myyntiä vastaavat muuttuvat ja kiinteät kulut. Katteen tulee olla suurempi kuin työntekijän palkkauksen kustannukset, jotta palkkaaminen on kannattavaa.",
        Luontoisedut:
          "Osa palkasta voidaan antaa työntekijöille luontoisetuina. Luontoisetu tarkoittaa muuna hyödykkeenä kuin rahana saatua korvausta. Näitä ovat esimerkiksi asuntoetu, autoetu, lounasetu ja puhelinetu",
        Tilitys:
          "Työnantajalla on velvollisuus tilittää palkoista pidätetyt verot ja sairasvakuutusmaksut kuukausittain Verohallinnolle",
        Työehtosopimu:
          "Työehtosopimuksessa on määräyksiä esim. työajasta, palkkauksesta ja vuosilomista. Mikäli alalla on käytössä työehtosopimus tai jos yritys kuuluu työnantajaliittoon, tulee näiden ehtoja noudattaa työsuhteessa",
        "Työntekijän palkkaamisen kannattavuusvaikutukset":
          "Työntekijän palkkaamisen tulee parantaa yrityksesi kannattavuutta. Esim. mikäli palkkaamasi myyjän kulut ovat 60 000 € / vuosi, tulee hänen myymiensä tuotteiden lisätä yrityksen katetta tätä enemmän.",
        Työsopimus:
          "Työsopimus on sopimus työntekijän ja työnantajan välillä, jossa sovitaan työtehtävistä, maksettavista palkoista sekä muista ehdoista ja eduista. Työsopimuksen voi tehdä suullisesti, mutta se kannattaa aina tehdä kirjallisessa muodossa.",
        "Yrittäjäeläke (yel)":
          "Yrittäjän omasta palkasta ei makseta tyel-maksua, vaan yrittäjän eläketurva perustuu yrittäjäeläkkeeseen (yel)",
      },
    ],
  },
};
