<template>
  <div class="app-footer" v-if="currentModule === true">
  {{currentModule}}
    <ProgressBar />
  </div>
</template>

<script>
import ProgressBar from "./ProgressBar.vue";
export default {
  name: "Progress Bar",
  components: {
    ProgressBar,
  },
  methods: {
    currentModule() {
      if (this.$store.getters.getCurrentModule !== null) {
        return false;
      } else return true;
    },
  },
};
</script>

<style>
</style>