<template>
  <div class="app-container" :class="routeName">
    <AppHeader v-if="this.$route.name !== 'Login'" />
    <router-view v-slot="{ Component }">
      <transition name="slide" mode="out-in">
        <component :is="Component"></component>
      </transition>
    </router-view>
    <AppFooter v-if="this.$route.name === 'Module'"/>
  </div>
</template>
<script>
import AppHeader from "./components/AppHeader.vue";
import AppFooter from "./components/AppFooter.vue";

export default {
  components: {
    AppHeader,
    AppFooter,
  },
  computed: {
    routeName() {
      if (this.$route.name) {
        let route = this.$route.name;
        return route.toLowerCase();
      } else {
        return "gewge";
      }
    },
  },
};
</script>
<style lang="scss">
@import "./src/css/style.scss";
</style>
