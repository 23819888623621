<template>
  <div class="content">
    <button @click="$emit('previousFrame')" class="btn btn-primary">
      <span class="color-error">{{ textLeft }}</span>
    </button>
    <button @click="$emit('nextFrame')" class="btn btn-primary">
      <span class="color-success">{{ textRight }}</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "FrameTwoButtons",
  props: {
    textLeft: String,
    textRight: String,
  },
};
</script>

<style>
</style>