<template>
  <swiper
    @swiper="onSwiper"
    :slides-per-view="1"
    :space-between="0"
    :speed="1000"
    :allowTouchMove="false"
    :pagination="false"
    :direction="'vertical'"
  >
    //intro slide
    <swiper-slide>
      <section class="frame scroll">
        <div class="content">
          <h3 class="color-accent">{{ moduleData.title }}</h3>
        </div>
        <div class="content">
          <h4>Haluatko katsoa videoita vai lukea tekstiä?</h4>
          <button @click="togglePref('text')" class="btn btn-primary">
            Teksti
          </button>
          <button @click="togglePref('video')" class="btn btn-primary">
            Video
          </button>
        </div>
        <div class="content" v-if="this.pref === 'video'">
          <div class="content" v-if="moduleData.video">
            <div class="video-square">
              <div v-if="!isPlaying" class="video-overlay">
                <button @click="toggleVideo()" class="btn">
                  <img src="../assets/play_overlay.png" />
                </button>
              </div>
              <video
                controls
                controlsList="nodownload"
                playsinline
                poster="../assets/videothumb.png"
                ref="introVideo"
              >
                <!-- <source :src="require(`../assets/video/${moduleData.video}`)" /> -->
                <source :src="moduleData.video" />
              </video>
            </div>
          </div>
        </div>
        <div class="content text-left" v-else-if="this.pref === 'text'">
          <p v-for="(p, n) in moduleData.intro" :key="'p' + n" v-html="p"></p>
        </div>
        <div class="content" v-if="this.pref !== ''">
          <button class="btn btn-primary" @click="nextFrame">
            <span class="color-success">Aloita moduuli</span>
          </button>
        </div>
      </section>
    </swiper-slide>

    //slides
    <template v-for="(slide, index) in moduleData.slides">
      <swiper-slide
        v-if="this.pref === 'text' && slide.type === 'text'"
        :key="slide.type + 'slide' + index"
      >
        <FrameSlideText
          :slide="slide"
          @nextFrame="nextFrame"
          @previousFrame="previousFrame"
        />
      </swiper-slide>
      <swiper-slide
        v-if="this.pref === 'text' && slide.type === 'img'"
        :key="'slide' + slide.type + index"
      >
        <FrameSlideImage
          :slide="slide"
          @nextFrame="nextFrame"
          @previousFrame="previousFrame"
        />
      </swiper-slide>
      <swiper-slide
        v-else-if="this.pref === 'video' && slide.type === 'video'"
        :key="this.moduleId + slide.type + 'slide' + index"
      >
        <section class="frame">
          <FrameSlideVideo :slide="slide" :ref="setVideoRefs" />
          <FrameTwoButtons
            @nextFrame="nextFrame"
            @previousFrame="previousFrame"
            textLeft="Edellinen"
            textRight="Seuraava"
          />
        </section>
      </swiper-slide>
    </template>

    //assignments //intro
    <swiper-slide>
      <section class="frame assignments-intro">
        <div class="content">
          <h4 class="color-accent">Tehtävät</h4>
        </div>
        <div class="content">
          <p>
            Katsottuasi videoluennot ja luettuasi luentodiat, voit siirtyä
            tekemään kurssitehtäviä, jotka koostuvat lyhyemmistä "Testaa
            osaamisesi"-tehtävistä sekä soveltavasta harjoitustehtävästä.
          </p>
        </div>
        <div class="content">
          <p>
            <strong
              >Tämä moduuli sisältää
              {{ moduleData.assignments.length }} tehtävää:</strong
            >
          </p>
          <template
            v-for="assignment in moduleData.assignments"
            :key="assignment.title"
          >
            <p v-html="assignment.title"></p>
          </template>
        </div>

        <div class="content">
          <FrameTwoButtons
            @nextFrame="nextFrame"
            @previousFrame="previousFrame"
            textLeft="Edellinen"
            textRight="Seuraava"
          />
        </div>
      </section>
    </swiper-slide>

    //assignments
    <template
      v-for="(assignment, index) in moduleData.assignments"
      :key="'assignment' + index"
    >
      <swiper-slide>
        <section class="frame">
          <div class="content">
            <h4 class="color-accent" v-html="assignment.title"></h4>
          </div>
          <div class="content">
            <img
              class="instructions-icon"
              src="../assets/img/instructions-icon.png"
            />
            <p>
              <span class="color-accent"><strong>Ohje:</strong></span> <br />{{
                assignment.text
              }}
            </p>
          </div>
          <FrameTwoButtons
            @nextFrame="nextFrame"
            @previousFrame="previousFrame"
            textLeft="Edellinen"
            textRight="Seuraava"
          />
        </section>
      </swiper-slide>

      //open questions
      <template v-if="assignment.type === 'text'">
        <swiper-slide
          v-for="(question, index) in assignment.questions"
          :key="'textquestion' + index"
        >
          <FrameQuestionText
            @nextFrame="nextFrame"
            @previousFrame="previousFrame"
            :h="question.h"
            :question="question.q"
            :attachments="question.attachments"
          />
        </swiper-slide>
      </template>
      <!-- //multi answer questions -->
      <template v-else-if="assignment.type === 'multi'">
        <swiper-slide
          v-for="(question, index) in assignment.questions"
          :key="'question' + index"
        >
          <FrameQuestionMulti
            @nextFrame="nextFrame"
            @previousFrame="previousFrame"
            :index="index"
            :question="question.q"
            :options="assignment.options"
            :answers="question.a"
          />
        </swiper-slide>
      </template>
      <!-- //Single answer questions -->
      <template v-else-if="assignment.type === 'boolean'">
        <swiper-slide
          v-for="(question, index) in assignment.questions"
          :key="'question' + index"
        >
          <FrameQuestionComplex
            @nextFrame="nextFrame"
            @previousFrame="previousFrame"
            :index="index"
            :question="question.q"
            :options="assignment.options"
            :answer="question.a"
          />
        </swiper-slide>
      </template>
      <template v-else>
        <swiper-slide
          v-for="(question, index) in assignment.questions"
          :key="'question' + index"
        >
          <FrameQuestionComplex
            @nextFrame="nextFrame"
            @previousFrame="previousFrame"
            :index="index"
            :question="question.q"
            :options="question.options"
            :answer="question.a"
          />
        </swiper-slide>
      </template>
    </template>

    //last slide
    <swiper-slide>
      <FrameSlideSummary
        @nextFrame="nextFrame"
        @previousFrame="previousFrame"
        @restartCourse="restartCourse"
      />
    </swiper-slide>
  </swiper>
</template>
<script>
import "@google/model-viewer";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

import FrameQuestionComplex from "../components/FrameQuestionComplex.vue";
import FrameQuestionMulti from "../components/FrameQuestionMulti.vue";
import FrameQuestionText from "../components/FrameQuestionText.vue";
import FrameTwoButtons from "../components/FrameTwoButtons.vue";
import FrameSlideText from "../components/FrameSlideText.vue";
import FrameSlideVideo from "../components/FrameSlideVideo.vue";
import FrameSlideImage from "../components/FrameSlideImage.vue";
import FrameSlideSummary from "../components/FrameSlideSummary.vue";

export default {
  name: "Dashboard",
  components: {
    Swiper,
    SwiperSlide,
    FrameQuestionText,
    FrameQuestionComplex,
    FrameQuestionMulti,
    FrameSlideText,
    FrameSlideVideo,
    FrameSlideImage,
    FrameTwoButtons,
    FrameSlideSummary,
  },
  data() {
    return {
      mainSwiper: null,
      videoRefs: [],
      isPlaying: false,
      pref:null,
    };
  },
  created(){
  },
  mounted() {
    const name = this.moduleData.title;
    const id = this.moduleId;
    this.$store.dispatch("setCurrent", id);
    this.$store.dispatch("setActive", { id, name });
    this.$store.dispatch("setSlideCount", { id:id, slideCount: this.mainSwiper.slides.length });
    this.mainSwiper.slideTo(this.currentSlide, false);
  },
  beforeUpdate() {
    this.videoRefs = [];
  },
  computed: {
    moduleId() {
      return this.$route.params.moduleid;
    },
    moduleData() {
      return this.$store.getters.moduleData(this.moduleId);
    },
    currentSlide() {
      return this.$store.getters.currentSlide(this.$route.params.moduleid);
    },
  },
  methods: {
    togglePref(mode) {
      this.pref = mode;
      const id = this.moduleId;
      this.$store.dispatch("setPref", mode);
      this.$nextTick(() => {
        this.$store.dispatch("setSlideCount", { id:id, slideCount: this.mainSwiper.slides.length });
      });
    },
    restartCourse() {
      const id = this.moduleId;
      this.$store.dispatch("setCurrentSlide", { id:id, currentSlide: 0});
      this.mainSwiper.slideTo(0);
    },
    setVideoRefs(el) {
      if (el) {
        this.videoRefs.push(el);
      }
    },
    toggleVideo() {
      if (this.isPlaying) {
        this.stopVideo();
        this.controls = "controls";
      } else {
        this.playVideo();
        this.controls = " ";
      }
    },
    playVideo() {
      this.$refs.introVideo.play();
      this.isPlaying = true;
    },
    stopVideo() {
      this.$refs.introVideo.pause();
      this.isPlaying = false;
    },
    onSwiper(swiper) {
      this.mainSwiper = swiper;
    },
    changeFrame(slide) {
      this.mainSwiper.slideTo(slide);
    },
    nextFrame() {
      if (this.$refs.introVideo) {
        this.$refs.introVideo.pause();
        this.videoRefs.forEach((element) => {
          element.stopVideo();
        });
      }
      this.mainSwiper.slideNext();
      const id = this.moduleId;
      this.$store.dispatch("setCurrentSlide", { id:id, currentSlide: this.mainSwiper.activeIndex});
    },
    previousFrame() {
      if (this.$refs.introVideo) {
        this.$refs.introVideo.pause();
        this.videoRefs.forEach((element) => {
          element.stopVideo();
        });
      }
      this.mainSwiper.slidePrev();
      const id = this.moduleId;
      this.$store.dispatch("setCurrentSlide", { id:id, currentSlide: this.mainSwiper.activeIndex});
    },
  },
};
</script>
