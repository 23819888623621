<template>
  <div class="app-header">
    <div class="row y-auto">
      <div class="col">
        <img
          alt="Aalto University"
          class="logo"
          :class="{ disabled: this.$route.name !== 'Dashboard' }"
          src="../assets/aalto.png"
        />
        <span
          @click="goBack()"
          class="btn btn-back"
          :class="{
            active:
              this.$route.name !== 'Dashboard' && this.$route.name !== 'Module',
          }"
          ><svg
            width="7"
            height="10"
            viewBox="0 0 7 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M5.5 1L1.5 5L5.5 9" stroke="#d04c51" stroke-width="2" />
          </svg>
          Takaisin
        </span>
        <span
          @click="goBack()"
          class="btn btn-exit"
          :class="{ active: this.$route.name === 'Module' }"
          ><svg
            width="7"
            height="10"
            viewBox="0 0 7 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M5.5 1L1.5 5L5.5 9" stroke="#d04c51" stroke-width="2" />
          </svg>
          Lopeta
        </span>
      </div>
      <div class="col text-right">
        <span
          v-if="
            this.$route.name === 'Settings' || this.$route.name === 'Glossary'
          "
        ></span>
        <span
          class="btn btn-dropdown"
          id="dd"
          v-else-if="
            this.$route.name !== 'Module' && this.$route.name !== 'Course'
          "
        >
          <svg viewBox="0 0 100 80" width="30" height="30">
            <rect width="100" height="10" fill="#ff356f"></rect>
            <rect y="30" width="100" height="10" fill="#ff356f"></rect>
            <rect y="60" width="100" height="10" fill="#ff356f"></rect>
          </svg>
          <ul>
            <li @click="logOut">Log out</li>
          </ul>
        </span>
        <span
          @click="openGlossary()"
          class="btn btn-neo btn-round btn-info"
          v-else-if="this.$route.name === 'Module'"
        >
          <!-- //Info -->
          <svg
            width="5"
            height="20"
            viewBox="0 0 5 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.21 5.8H0.0100001V22H4.21V5.8ZM4.21 0.129999H0.0100001V3.88H4.21V0.129999Z"
              fill="#ff356f"
            />
          </svg>
        </span>
      </div>
    </div>
  </div>
  <AppModal v-show="modalVisible" @close="closeModal" @quit="quitModal" />
</template>
<script>
import AppModal from "./AppModal.vue";
import firebase from "../firebaseInit";

export default {
  name: "AppHeader",
  data() {
    return {
      modalVisible: false,
    };
  },
  components: {
    AppModal,
  },
  methods: {
    goBack() {
      if (this.$route.name === "Module") {
        this.modalVisible = true;
      } else {
        this.$router.go(-1);
      }
    },
    closeModal() {
      this.modalVisible = false;
    },
    quitModal() {
      this.modalVisible = false;
      this.$router.go(-1);
    },
    openGlossary() {
      this.$router.push({
        name: "Glossary",
        params: { moduleid: this.$route.params.moduleid },
      });
    },
    logOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.push("/Login");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
